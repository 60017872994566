import { useRouter } from 'next/router';
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Button,
  Alert,
  DrawerModal,
  NumberStepper,
  ImageMissing,
  Input,
  StockIndicators,
} from '@fagmobler/ui';
import Image from 'next/image';
import { useNavigation } from '@/contexts/navigation';
import { useForm, useFormState } from 'react-hook-form';
import clsx from 'clsx';
import { useOpeningHours } from '../store';
import { trackEvent } from '@/lib/analytics';
import { Price } from '@/cataloguePages/productPage/components/Price';

const isProduction = process.env.NODE_ENV === 'production';

export function ClickAndCollectModal() {
  const [isLoading, setIsLoading] = useState(false);
  const [complete, setComplete] = useState<boolean | string>(false);
  // const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const router = useRouter();
  const { clickAndCollect, setClickAndCollect } = useNavigation();

  const { stock, product, quantity, store, visible } = clickAndCollect || {};
  const openingHours = useOpeningHours(store, true);
  const hasImage = !!product?.mainImageUrl;

  const {
    register,
    handleSubmit,
    resetField,
    setError,
    getValues,
    formState: { errors },
  } = useForm();

  async function onSubmit(data: Record<string, string>) {
    setIsLoading(true);

    const phone = data.phone.replace(/\s/g, '');

    let jsonBody: any;

    try {
      jsonBody = JSON.stringify({
        phone,
        storeId: store?.id,
        skuId: product?.skuId,
        quantity,
      });
    } catch (e) {
      setIsLoading(false);
      // setErrorMessage('Noe gikk galt. Vennligst prøv igjen senere.');
      return;
    }

    const res = await fetch('/api/checkout/click-and-collect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonBody,
    });

    if (!res) {
      console.error('No response from server');
      setIsLoading(false);
      // setErrorMessage('Noe gikk galt. Vennligst prøv igjen senere.');
      return;
    }

    const result = await res.json();

    if (result.errors) {
      console.log('errors', result.errors);
      const errorKeys = Object.keys(result.errors);
      console.log();
      if (errorKeys.length) {
        errorKeys.forEach((key) => {
          const message = result.errors[key].join(', ');
          setError(key, { message }, { shouldFocus: true });
        });
      }

      setIsLoading(false);
      return;
    }

    if (result.oid) {
      // await router.push(`/utsjekk/bekreftelse?oid=${result.oid}`);
      // setClickAndCollect(undefined);
      trackEvent(
        'purchase',
        {
          value: (product?.price?.unitPrice || 0) * (quantity || 1) || 0,
          currency: 'NOK',
          transaction_id: result.oid,
          items: [
            {
              item_id: product?.skuId,
              item_name: product?.name,
              price: product?.price?.unitPrice || 0,
              quantity,
            },
          ],
        },
        store || undefined
      );
      setComplete(result.oid);
    }

    setIsLoading(false);
  }

  const { phone } = getValues();

  const isInStock = quantity && stock && stock.stock >= quantity;

  return (
    <DrawerModal
      isOpen={!!visible}
      onClose={() => setClickAndCollect(undefined)}
      title="Klikk og hent"
    >
      <div className="p-4 md:p-6 border-b border-b-neutral-50">
        <h3 className="hd-md  mb-2">{store?.name}</h3>
        <p className="body-sm">
          {store?.address?.streetName} {store?.address?.streetNumber}
        </p>
        <h3 className="hd-md mt-4 mb-2">Åpningstider</h3>
        {Object.entries(openingHours).map(([key, value]) => (
          <p
            key={key}
            className={clsx(
              'flex',
              'justify-between',
              'body-sm',
              'mr-8',
              'space-y-2'
            )}
          >
            <span>{key}</span>
            <span className="">
              {value === 'stengt' ? (
                <span className="text-primary-50">{value}</span>
              ) : (
                value
              )}
            </span>
          </p>
        ))}
      </div>

      {product && (
        <section className="flex flex-col h-full">
          <ol className="p-4 md:p-6">
            <section className="flex items-start">
              <div className="aspect-square bg-neutral-30 rounded relative mr-4 w-52 h-52">
                {hasImage ? (
                  <Image
                    className="mix-blend-darken p-2 object-contain w-52 h-52"
                    alt={product.name}
                    src={product.mainImageUrl!}
                    width="208"
                    height="208"
                  />
                ) : (
                  <div className="w-52 h-52">
                    <ImageMissing size="sm" />
                  </div>
                )}
              </div>

              <div>
                <header>
                  <h3 className="hd-base">{product.name}</h3>
                </header>
                {product.description && (
                  <p className="body-xs">{product.description}</p>
                )}

                <footer>
                  <div className="mt-4 mb-2 pr-4">
                    <StockIndicators stock={stock} cartQuanitity={quantity} />
                  </div>
                  <Price //TODO: replace this with the @fagmobler/ui component
                    product={product}
                    isMemberPrice={product.price.promotionId === 'Member'}
                  />
                  <div>
                    <NumberStepper
                      value={quantity || 0}
                      onDecrement={function (): void {
                        setClickAndCollect({
                          ...clickAndCollect,
                          quantity: quantity && quantity > 1 ? quantity - 1 : 1,
                        });
                      }}
                      onIncrement={function (): void {
                        setClickAndCollect({
                          ...clickAndCollect,
                          quantity: quantity ? quantity + 1 : 1,
                        });
                      }}
                    />
                  </div>
                </footer>
              </div>
            </section>
          </ol>
          <div className="px-6 space-y-5">
            {!isInStock && !complete && (
              <Alert type="warning" className="mb-4">
                Klikk og hent er bare tilgjengelig hvis alle varene er på lager
                hos din valgte butikk.
              </Alert>
            )}

            {isInStock && !complete && (
              <>
                <Alert type="info" className="mb-4">
                  Varen er på lager. Vi sender deg en SMS når varen er klar til
                  henting. Du betaler i butikken når du henter varen.
                </Alert>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    label="Mobil"
                    placeholder="Fyll inn mobilnummer"
                    error={(errors.phone?.message as string) || undefined}
                    resetField={() => resetField('phone')}
                    type="tel"
                    {...register('phone', {
                      required: 'Vennligst oppgi telefon',
                      maxLength: 100,

                      validate: (value) => {
                        return (
                          isValidPhoneNumber(value, { defaultCountry: 'NO' }) ||
                          'Ugyldig telefonnummer'
                        );
                      },
                    })}
                  />
                  {!isProduction && errors.server?.message && (
                    <Alert type="error" className="mb-4">
                      {errors.server.message.toString()}
                    </Alert>
                  )}
                  {errors.userFeedback && (
                    <Alert type="error" className="mb-4">
                      {errors.userFeedback.message?.toString()}
                    </Alert>
                  )}
                  <Button
                    className="w-full justify-center"
                    size="lg"
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Reserver i butikk
                  </Button>
                </form>
              </>
            )}
            {complete && (
              <Alert type="success" className="mb-4">
                <span className="hd-md mb-2 block">
                  Varen din er reservert i butikk.
                </span>
                <span className="body-base block">
                  Vi sender deg en SMS på <strong>{phone}</strong> når varen er
                  klar til henting. Du betaler når du henter varen.
                </span>
              </Alert>
            )}
          </div>
        </section>
      )}
    </DrawerModal>
  );
}
